var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'mx-lg-3': true,
  'd-lg-block': !_vm.mobile,
  'd-none': !_vm.mobile,
  'd-lg-none': _vm.mobile,
  'd-md-block': _vm.mobile,
  'search-form': true
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cityquery),expression:"cityquery"}],staticClass:"form-control search-box me-2",attrs:{"type":"search","placeholder":"Discover your city here","aria-label":"Search"},domProps:{"value":(_vm.cityquery)},on:{"focus":_vm.focus,"blur":_vm.blur,"keyup":_vm.autocomplete_city,"input":function($event){if($event.target.composing)return;_vm.cityquery=$event.target.value}}}),_c('a',{staticClass:"search-button",attrs:{"href":'/search?location=' + this.cityquery}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 34 34","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15.4062 26.5625C21.5677 26.5625 26.5625 21.5677 26.5625 15.4062C26.5625 9.24482 21.5677 4.25 15.4062 4.25C9.24482 4.25 4.25 9.24482 4.25 15.4062C4.25 21.5677 9.24482 26.5625 15.4062 26.5625Z","stroke":"#EFEFEF","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M23.2949 23.2954L29.7497 29.7502","stroke":"#EFEFEF","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),(_vm.state=='optionsMainScreen' && _vm.featuredCities.length > 0)?_c('div',{class:{
        'options': true,
        'main-screen': true,
        'mobile': _vm.mobile
      }},[_c('h5',[_vm._v("Cities on StoryFaith")]),_vm._l((_vm.featuredCities),function(option,i){return _c('a',{key:i,staticClass:"option",attrs:{"href":_vm.href(option)}},[_vm._v(" "+_vm._s(option.city)+", "+_vm._s(option.state_name)+" ")])})],2):_vm._e(),(_vm.state=='autocomplete' && _vm.autocompleteOptions.length > 0)?_c('div',{class:{
        'options': true,
        'mobile': _vm.mobile
      }},_vm._l((_vm.autocompleteOptions),function(option,i){return _c('a',{key:i,staticClass:"option",attrs:{"href":_vm.href(option)}},[_vm._v(" "+_vm._s(option.city)+", "+_vm._s(option.state_name)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }