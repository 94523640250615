<template>
  <div :class="{
    'mx-lg-3': true,
    'd-lg-block': !mobile,
    'd-none': !mobile,
    'd-lg-none': mobile,
    'd-md-block': mobile,
    'search-form': true
    }">
      <input
        class="form-control search-box me-2"
        type="search"
        placeholder="Discover your city here"
        aria-label="Search"
        @focus="focus"
        @blur="blur"
        @keyup="autocomplete_city"
        v-model="cityquery"
        />
      <a  class="search-button" :href="'/search?location=' + this.cityquery ">
        <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.4062 26.5625C21.5677 26.5625 26.5625 21.5677 26.5625 15.4062C26.5625 9.24482 21.5677 4.25 15.4062 4.25C9.24482 4.25 4.25 9.24482 4.25 15.4062C4.25 21.5677 9.24482 26.5625 15.4062 26.5625Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23.2949 23.2954L29.7497 29.7502" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
      <div
        :class="{
          'options': true,
          'main-screen': true,
          'mobile': mobile
        }"
        v-if="state=='optionsMainScreen' && featuredCities.length > 0"
      >
        <h5>Cities on StoryFaith</h5>
        <a
          v-for="(option, i) in featuredCities"
          :key="i"
          :href="href(option)"
          class="option"
          >
            {{option.city}}, {{option.state_name}}
        </a>
      </div>
      <div
        :class="{
          'options': true,
          'mobile': mobile
        }"
        v-if="state=='autocomplete' && autocompleteOptions.length > 0"
        >

        <a
          v-for="(option, i) in autocompleteOptions"
          :key="i"
          :href="href(option)"
          class="option"
          >
            {{option.city}}, {{option.state_name}}
        </a>
      </div>
  </div>
</template>
<script>
// Fetch compatibility
import 'whatwg-fetch'

export default {
    name: 'TopSearch',
    props: {
      mobile: Boolean
    },
    data () {
        return {
            cityquery: "",
            autocompleteOptions: [],
            featuredCities: [],
            state: "closed"
        };
    },
    methods: {
        autocomplete_city () {
            let self = this
            if (this.cityquery.length >= 3 && this.cityquery.length < 100) {
                fetch("/cityquery?q=" + this.cityquery)
                        .then(response => response.json())
                        .then( (data) => {
                          self.autocompleteOptions = data
                          self.state="autocomplete"
                        });

            } else {
                this.autocompleteOptions = [];
                self.state="optionsMainScreen"
            }
        },
        clear_city () {
          this.state="closed"
          this.autocompleteOptions = [];
        },
        select_city (city) {
            this.cityquery = city.city + ", " + city.state_name;
            this.clear_city();

        },
        focus () {
          if (self.timer) {
            clearTimeout(self.timer)
          }
          this.state = 'optionsMainScreen'
        },
        blur () {
          let self = this
          if (this.state !== "autocomplete")
            self.timer = window.setTimeout( () => {
              self.state = "closed"
              self.timer = null
            }, 200 )
        },
        href (city) {
          return '/search?location=' + city.city + ", " + city.state_name;
        }
    },
    created () {
      let self = this
      fetch("/featured_cities")
              .then(response => response.json())
              .then( (data) => {
                self.featuredCities = data
              });
    }
}
</script>
<style scoped>
.search-form {
    position: relative;
}
.options, .options.mobile {
    position: absolute;
    top: 40px;
    left: 14px;
    background: white;
    min-width: 250px;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    z-index: 2;
}
.options > .option {
    border-bottom: 1px solid #ddd;
    padding: 0.5em;
    cursor: pointer;
    text-decoration: none;
    color: black;
    display: block;
}
.options > .option:hover {
    background: #efefef;
}

h5 {
  font-weight: lighter;
  font-size: 14px;
  margin: 0 0 10px 0;
}

.main-screen {
  padding: 0.7em 0.5em;
}

@media(max-width: 770px) {
    .options.mobile {
        top: 40px;
    }
    .options {
        top: 80px;
    }
}

</style>
