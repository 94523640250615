<template>
  <div :class='{
        "public-position": publicFrontPage,
        "searchbox": true,
        "shadow": true,
        "d-flex": true,
        "flex-column": true,
        "flex-md-row": true
      }'>
      <div class="section d-flex flex-fill flex-column location">
          <label>Location</label>
          <input
            class="mt-auto"
            type="text"
            placeholder="Where do you live?"
            @keyup="autocomplete_city"
            @focus="focus"
            @blur="blur"
            v-model="cityQuery"
            />
          <div class="options" v-if="state=='autocomplete' && autocompleteOptions.length > 0">
              <div
                v-for="(option, i) in autocompleteOptions"
                :key="i"
                @click="select_city(option)"
                class="option"
                >
                  {{option.city}}, {{option.state_name}}
              </div>
          </div>
          <div class="background" />
          <div
            class="options main-screen"
            v-if="(state=='optionsMainScreen' && featuredCities.length > 0)"
            >
            <h5>Cities on StoryFaith</h5>
            <a
              v-for="(option, i) in featuredCities"
              :key="i"
              @click="select_city(option)"
              class="option"
              >
                {{option.city}}, {{option.state_name}}
            </a>
          </div>
      </div>
      <div class="section  d-flex flex-fill flex-column type" >
          <label>What are you looking for?</label>
          <input class="mt-auto" type="text" placeholder="ex. College Ministry, Local Church" v-model="typeQuery" @focus="typeSelect = true" />
          <div class="options" v-if="typeSelect">
              <div v-for="(option, i) in typeOptions" class="option" :key="i" @click="selectType(option)">
                  {{option.text}}
              </div>
          </div>
          <div class="background" />
      </div>
      <a class="search-btn d-flex align-self-center" :href="'/search?location=' + this.cityQuery + '&q=' + this.typeQuery">
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="28" r="28" fill="#222222"/>
          <path d="M26.3594 37.8438C32.702 37.8438 37.8438 32.702 37.8438 26.3594C37.8438 20.0167 32.702 14.875 26.3594 14.875C20.0167 14.875 14.875 20.0167 14.875 26.3594C14.875 32.702 20.0167 37.8438 26.3594 37.8438Z" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M34.4795 34.4805L41.1241 41.1251" stroke="#EFEFEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="d-md-none d-block"> Search </span>
      </a>
  </div>
</template>

<script>
// Fetch compatibility
import 'whatwg-fetch'

export default {
  name: 'Search',
  props: {
    publicFrontPage: Boolean
  },
  data () {
      return {
          cityQuery: "", // "Gainesville, Florida",
          typeQuery: "",
          autocompleteOptions: [],
          featuredCities: [],
          state: "closed",
          typeOptions: [
              {
                  "text": "Local Church"
              },
              {
                  "text": "College Ministry"
              }
          ],
          typeSelect: false
      };
  },
  created () {
    let self = this
    fetch("/featured_cities")
            .then(response => response.json())
            .then( (data) => {
              self.featuredCities = data

            });

  },
  methods: {
      autocomplete_city () {
          /*this.autocompleteOptions = [
              {
                city: "Gainesville", state_name: "Florida"
              }
          ]
          /* Disable temporarily */
          let self = this
          if (this.cityQuery.length >= 3 && this.cityQuery.length < 100) {
              fetch("/cityquery?q=" + this.cityQuery)
                      .then(response => response.json())
                      .then((data) => {
                        self.autocompleteOptions = data
                        self.state="autocomplete"
                      });

          } else {
              this.autocompleteOptions = [];
              self.state="optionsMainScreen"
          }
      },
      clear_city () {
          this.state="closed"
          this.autocompleteOptions = [];
      },
      select_city (city) {
          this.cityQuery = city.city + ", " + city.state_name;
          this.clear_city();
      },
      selectType (type) {
          this.typeQuery = type.text;
          this.typeSelect = false;
      },
      focus () {
        if (self.timer) {
          clearTimeout(self.timer)
          self.timer = null
        }
        this.state = 'optionsMainScreen'
      },
      blur () {
        let self = this
        if (this.state !== "autocomplete")
          self.timer = window.setTimeout( () => {
            self.state = "closed"
            self.timer = null
          }, 200 )
      },
      href (city) {
        return '/search?location=' + city.city + ", " + city.state_name;
      }
  }
}
</script>
<style scoped>
.public-position {
    position: relative;
    top: -50px;
    margin: 0px auto;
}

.searchbox {
    border-radius: 50px;
    background: white;
    font-size: 16px;
    padding: 10px 10px;
    max-width: 700px;
    width: auto;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.4);
}

label {
    font-weight: bold;
}

.section {
    padding: 5px 30px;
    position: relative;

}
.section:first-child {
    border-right: 1px solid #ddd;
}
.section .background {
    opacity: 0;
    transition: opacity 0.5s;
}
.section:hover .background {
    opacity: 1;
}
.section:hover input[type="text"] {
    background: #f3f3f3;
}

.section input {
    border: 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    display: block;
    padding: 5px;
    z-index: 1;
    position: relative;
    background: #fff;
    transition: background-color 0.5s;
}
.section input[type="text"]:focus {
    outline: none;
}
.section label {
    z-index: 1;
    position: relative;
}

.options {
    position: absolute;
    top: 60px;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    z-index: 2;
}
.options > .option {
    border-bottom: 1px solid #ddd;
    padding: 8px 18px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    display: block;
}
.options > .option:hover {
    background: #efefef;
}


h5 {
  font-weight: lighter;
  font-size: 14px;
  margin: 10px;
}

.main-screen {
  padding-bottom: 10px;
}


.search-btn {
    background: #222222;
    border-radius: 30px;
    padding: 0px;
    margin-right: 7px;
    margin-left: 10px;
}
.search-btn svg circle {
    transition: fill 0.24s;
    fill: #222;
}
.search-btn:hover svg circle {
    fill: #7B7B7B;
}
.search-btn span {
    color: white;
    padding: 14px 25px 0px 0px;
}


@media(max-width: 770px) {
    .searchbox {
        border-radius: 25px;
        padding: 30px 30px;
        margin: 20px;
        top: -70px;
    }
    .search-btn {
        margin-top: 15px;
    }
    .section {
        padding: 15px 0px;
    }
    .section:first-child {
        border-right: 0;
    }
    .options {
        top: 80px;
    }


    .section .background {
        display: none;
    }
}

.section .background {
    background-color: #f3f3f3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
}
.section.location .background {
    border-radius: 50px 0 0 50px;

}

</style>
