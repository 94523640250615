import Vue from 'vue'
import Search from './Search.vue'
import TopSearch from './TopSearch.vue'
import Content from './Content.vue'


if (document.getElementById("search-bar")) {
    new Vue({
      render: h => h(Search, {
        props: {
          publicFrontPage: true
        }
      }),
    }).$mount('#search-bar')
}

if (document.getElementById("top-search-bar")) {
    new Vue({
      render: h => h(TopSearch, {
        mobile: false
      }),
    }).$mount('#top-search-bar')
}

if (document.getElementById("top-search-bar-mobile")) {
    new Vue({
      render: h => h(TopSearch, {
        props: {
          mobile: true
        }
      }),
    }).$mount('#top-search-bar-mobile')
}

if (document.getElementById("index-content")) {
    new Vue({
      render: h => h(Content, {
        props: {
          mobile: window.innerWidth < 600
        }
      })
    }).$mount("#index-content");
}
