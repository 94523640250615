<template>
  <div class="index-content" v-if="!mobile">
    <div v-if="communities.length > 0" class="featured">
        <div class="container">
            <h2 class="text-start">Featured Communities</h2>
            <div class="cards d-flex flex-nowrap" ref="communitiesContainer">
                <div class="col-12 col-md-4 col-sm-6 col-lg-3 d-flex" v-for="comm,i in communities" :key="i">
                    <div class="card d-flex shadow align-items-start ">
                        <a class="img-link" :href="comm.url"><img :src="comm.thumbnail" class="card-img-top"></a>
                        <div class="card-body">
                            <h5 class="card-title">
                              {{ comm.title }}
                            </h5>
                            <h4>{{comm.university_name}}</h4>
                            <p>{{comm.small_description}}</p>

                        </div>
                        <div class="p-3 mt-auto text-center align-self-stretch">
                            <a class="btn btn-dark"  :href="comm.url">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn-nav right" @click="communityNavRight">
              <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.91309 1.28247L14.6305 13.9999L1.91309 26.7173" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button class="btn-nav left" @click="communityNavLeft">
              <svg width="16" height="28" viewBox="-16 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-1.91309 1.28247L-14.6305 13.9999L-1.91309 26.7173" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
        </div>
    </div>
    <div v-if="events.length > 0" class="featured">
        <div class="container">
            <h2 class="text-start">Featured Events</h2>
            <div class="cards d-flex flex-nowrap" ref="eventsContainer" >
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 d-flex" v-for="event,i in events" :key="i">
                    <div class="card w-100 d-flex shadow align-items-start ">
                        <a class="img-link" :href="event.url"><img :src="event.thumbnail" class="card-img-top"></a>
                        <div class="card-body">
                            <h5 class="card-title">
                              {{ event.title }}
                            </h5>
                            <h4 class="small">{{event.address}}</h4>
                            <p>
                              <span v-if="event.schedule" class="card-detail">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10"/>
                                <path d="M8 4.5V8H11.5" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                {{event.schedule}}
                              </span>
                              <span v-if="event.rsvps" class="card-detail">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 10C7.29493 10 8.75 8.54493 8.75 6.75C8.75 4.95507 7.29493 3.5 5.5 3.5C3.70507 3.5 2.25 4.95507 2.25 6.75C2.25 8.54493 3.70507 10 5.5 10Z" stroke="#333333" stroke-width="2" stroke-miterlimit="10"/>
                                <path d="M9.71289 3.62132C10.1599 3.49537 10.6287 3.46668 11.0878 3.53718C11.5468 3.60767 11.9854 3.77572 12.374 4.02999C12.7626 4.28427 13.0922 4.61887 13.3407 5.01127C13.5891 5.40366 13.7505 5.84473 13.8141 6.30477C13.8777 6.76481 13.842 7.23314 13.7093 7.67821C13.5767 8.12329 13.3502 8.53477 13.0452 8.88494C12.7401 9.23511 12.3636 9.51585 11.9409 9.70824C11.5182 9.90063 11.0592 10.0002 10.5947 10.0003" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1 12.3373C1.50758 11.6153 2.18143 11.026 2.96466 10.6192C3.74788 10.2124 4.61748 10 5.50005 10C6.38262 9.99997 7.25224 10.2123 8.0355 10.619C8.81875 11.0258 9.49265 11.615 10.0003 12.337" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.5957 10C11.4784 9.99936 12.3482 10.2114 13.1315 10.6182C13.9148 11.025 14.5886 11.6146 15.0957 12.337" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                {{event.rsvps}} people will join
                              </span>
                            </p>

                        </div>
                        <div class="p-3 mt-auto text-center align-self-stretch">
                            <a class="btn btn-dark" :href="event.url">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn-nav right" @click="eventNavRight">
              <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.91309 1.28247L14.6305 13.9999L1.91309 26.7173" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button class="btn-nav left" @click="eventNavLeft">
              <svg width="16" height="28" viewBox="-16 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-1.91309 1.28247L-14.6305 13.9999L-1.91309 26.7173" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
        </div>
    </div>
  </div>
</template>
<script>
// Fetch compatibility
import 'whatwg-fetch'

export default {
  name: 'Content',
  props: {
    mobile: Boolean
  },
  data () {
      return {
          communities: [],
          events: []
      }
  },
  methods: {
      communityNavLeft () {
          if (this.communities.length == 0) return;
          let cardWidth = this.$refs.communitiesContainer.scrollWidth / this.communities.length;
          this.$refs.communitiesContainer.scrollLeft -= cardWidth;
      },
      communityNavRight () {
          if (this.communities.length == 0) return;
          let cardWidth = this.$refs.communitiesContainer.scrollWidth / this.communities.length;
          this.$refs.communitiesContainer.scrollLeft += cardWidth;
      },
      eventNavLeft () {
          if (this.events.length == 0) return;
          let cardWidth = this.$refs.eventsContainer.scrollWidth / this.events.length;
          this.$refs.eventsContainer.scrollLeft -= cardWidth;
      },
      eventNavRight () {
          if (this.events.length == 0) return;
          let cardWidth = this.$refs.eventsContainer.scrollWidth / this.events.length;
          this.$refs.eventsContainer.scrollLeft += cardWidth;
      }
  },
  created () {
      var self = this;
      fetch("/api/community/home/").then(async function(result) {
          self.communities = await result.json();
      });
      fetch("/api/event/home/").then(async function(result) {
          self.events = await result.json();
      });
  }
}
</script>
<style scoped>
.container {
    position: relative;
}
.cards {
    overflow-x: scroll;
    padding-bottom: 20px;
}
.btn-nav:hover {
    background: #f0f0f0;
}
.btn-nav {
    background: #fcfcfc;
    border-radius: 30px;
    padding: 15px 20px;
    border: 0;
    outline: 0;
    z-index: 2;
    position: absolute;
    top: 250px;
}
.btn-nav.left {
    left: -25px;
}
.btn-nav.right {
    right: -25px;
}

.featured {
    margin-bottom: 100px;
}
.featured .card {
    margin: 0px 5px;
}
.featured .cards div .card img, .featured .cards .card  {
    border-radius: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 992px) {
    .featured {
        padding: 20px;
    }
    .featured .container {
        max-width: none;
    }
}
@media (min-width: 992px) {
    .featured .container {
        width: auto;
        max-width: 1100px;
    }
}
@media (min-width: 1200px) {
    .featured .container {
        width: 1200px;
    }
}
.featured .cards div:first-child .card {
    border-radius: 25px 0px 0px 20px;
}
.featured .cards div:first-child .card img {
    border-radius: 25px 0px 0px 0px;
}
.featured .cards div:last-child .card {
    border-radius: 0px 25px 25px 0px;
}
.featured .cards div:last-child .card img {
    border-radius: 0px 25px 0px 0px;
}

</style>
